<template>
  <app-content-layout>
    <app-sidebar :items="menus" />
    <router-view />
  </app-content-layout>
</template>

<script>
  /**
   * @project personal-idenfit
   * @developer Halil Kılıçarslan
   * @description Profile Component Logic
   * @date 25.07.2020
   */
  import { mapGetters } from "vuex";

  export default {
    name: "profile",
    data: () => ({
      menus: [],
      employee: {}
    }),
    computed: {
      ...mapGetters({
        routeDisabled: "auth/routeDisabled",
        isSuperAdmin: "auth/isSuperAdmin"
      }),
      items() {
        return [
          {
            title: this.$t("hr.menu.profile"),
            to: { name: "employee" },
            icon: "mdi-account",
            active: true,
            visible: true
          },
          {
            title: this.$t("hr.menu.performances"),
            to: { name: "performances" },
            icon: "tw-hr-performance",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.PERFORMANCE_DISABLED
          },
          {
            title: this.$t("hr.menu.documents"),
            to: { name: "documents" },
            icon: "tw-hr-documents",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.DOCUMENT_DISABLED
          },
          {
            title: this.$t("hr.menu.health"),
            to: { name: "health" },
            icon: "tw-hr-health",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.HEALTH_DISABLED
          },
          {
            title: this.$t("hr.menu.payroll"),
            to: { name: "payroll" },
            icon: "tw-hr-tasks",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.PAYROLL_DISABLED
          },
          {
            title: this.$t("hr.menu.events"),
            to: { name: "events" },
            icon: "mdi-calendar-clock",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.ACTIVITY_DISABLED
          },
          {
            title: this.$t("hr.menu.suggestions"),
            to: { name: "suggestions" },
            icon: "mdi-help-circle",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.SUGGESTION_DISABLED
          },
          {
            title: this.$t("hr.menu.notes"),
            to: { name: "notes" },
            icon: "tw-hr-notes",
            active: true,
            visible: true
          },
          {
            title: this.$t("hr.menu.menus"),
            to: { name: "menus" },
            icon: "mdi-food",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.MENU_DISABLED
          },
          {
            title: this.$t("hr.menu.contact"),
            to: { name: "contacts" },
            icon: "mdi-contacts",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.CONTACT_DISABLED
          },
          {
            title: this.$t("hr.menu.paper"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.PAPER_DISABLED,
            items: [
              {
                title: this.$t("pendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle",
                access_key: "hrManager",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.PAPER_DISABLED,
                to: { name: "paper_approval" }
              },
              {
                title: this.$t("allPendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                access_key: "hrManager",
                active: true,
                visible: this.isSuperAdmin,
                mobileSetting: this.$enums.MOBILE_SETTINGS.PAPER_DISABLED,
                to: { name: "paper_approval_all" }
              },
              {
                title: this.$t("hr.menu.papers"),
                to: { name: "papers" },
                icon: "tw-hr-papers",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.paper_request_history"),
                to: { name: "paper_request_history" },
                icon: "mdi-history",
                access_key: "hrManager",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.survey"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.SURVEY_DISABLED,
            items: [
              {
                title: this.$t("hr.menu.pending_surveys"),
                to: { name: "survey_pending" },
                icon: "mdi-chart-box",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.completed_surveys"),
                to: { name: "survey_completed" },
                icon: "mdi-clipboard-check",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.expired_surveys"),
                to: { name: "survey_expired" },
                icon: "mdi-lock-clock",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.training"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.TRAINING_DISABLED,
            items: [
              {
                title: this.$t("pendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle",
                access_key: "hrManager",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.TRAINING_DISABLED,
                to: { name: "training_approval" }
              },
              {
                title: this.$t("allPendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                access_key: "hrManager",
                active: true,
                visible: this.isSuperAdmin,
                mobileSetting: this.$enums.MOBILE_SETTINGS.TRAINING_DISABLED,
                to: { name: "training_approval_all" }
              },
              {
                title: this.$t("hr.menu.trainings"),
                to: { name: "trainings" },
                icon: "tw-hr-trainings",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.training_requests"),
                to: { name: "training_request" },
                icon: "mdi-school",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.training_request_history"),
                to: { name: "training_request_history" },
                icon: "mdi-history",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.expense"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.EXPENSE_DISABLED,
            items: [
              {
                title: this.$t("pendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle",
                access_key: "expenseManager",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.EXPENSE_DISABLED,
                to: { name: "expense_approval" }
              },
              {
                title: this.$t("allPendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                access_key: "expenseManager",
                active: true,
                visible: this.isSuperAdmin,
                mobileSetting: this.$enums.MOBILE_SETTINGS.EXPENSE_DISABLED,
                to: { name: "expense_approval_all" }
              },
              {
                title: this.$t("hr.menu.expenses"),
                to: { name: "expenses" },
                icon: "tw-hr-expenses",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.expense_request_history"),
                to: { name: "expense_request_history" },
                icon: "mdi-history",
                access_key: "expenseManager",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.imprest"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.IMPREST_DISABLED,
            items: [
              {
                title: this.$t("pendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.IMPREST_DISABLED,
                to: { name: "imprest_approval" }
              },
              {
                title: this.$t("allPendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                active: true,
                visible: this.isSuperAdmin,
                mobileSetting: this.$enums.MOBILE_SETTINGS.IMPREST_DISABLED,
                to: { name: "imprest_approval_all" }
              },
              {
                title: this.$t("hr.menu.imprests"),
                to: { name: "imprests" },
                icon: "tw-hr-cost",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.imprest_request_history"),
                to: { name: "imprest_request_history" },
                icon: "mdi-history",
                access_key: "hrManager",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.travel"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.TRAVEL_DISABLED,
            items: [
              {
                title: this.$t("pendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.TRAVEL_DISABLED,
                to: { name: "travel_approval" }
              },
              {
                title: this.$t("allPendingRequests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                active: true,
                visible: this.isSuperAdmin,
                mobileSetting: this.$enums.MOBILE_SETTINGS.TRAVEL_DISABLED,
                to: { name: "travel_approval_all" }
              },
              {
                title: this.$t("hr.menu.travels"),
                to: { name: "travels" },
                icon: "mdi-wallet-travel",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.travel_request_history"),
                to: { name: "travel_request_history" },
                icon: "mdi-history",
                access_key: "hrManager",
                active: true,
                visible: true
              }
            ]
          },
          {
            title: this.$t("hr.menu.debit_tickets"),
            to: "",
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.DEBIT_TICKET_DISABLED,
            items: [
              {
                title: this.$t("hr.menu.debit_ticket_transfer_requests"),
                icon: "mdi-checkbox-multiple-marked-circle-outline",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.DEBIT_TICKET_DISABLED,
                to: { name: "debit_ticket_transfer_approval" }
              },
              {
                title: this.$t("hr.menu.debit_tickets"),
                to: { name: "debit_tickets" },
                icon: "mdi-layers",
                active: true,
                visible: true
              },
              {
                title: this.$t("hr.menu.vehicles"),
                to: { name: "vehicles" },
                icon: "mdi-car",
                active: true,
                visible: true,
                mobileSetting: this.$enums.MOBILE_SETTINGS.DEBIT_TICKET_DISABLED
              }
            ]
          },
          {
            title: this.$t("hr.menu.messages"),
            to: { name: "messages" },
            icon: "",
            active: true,
            visible: true,
            mobileSetting: this.$enums.MOBILE_SETTINGS.MESSAGE_DISABLED,
            items: [
              {
                title: this.$t("hr.menu.inbox"),
                icon: "mdi-inbox",
                active: true,
                visible: true,
                to: { name: "message_inbox" }
              },
              {
                title: this.$t("hr.menu.outbox"),
                icon: "mdi-send",
                active: true,
                visible: true,
                to: { name: "message_outbox" }
              }
            ]
          }
        ];
      },
      menuItems() {
        return this.items.filter((item) => {
          if (Array.isArray(item.items)) {
            item.items = item.items.filter((subItem) => !this.routeDisabled(subItem.mobileSetting));
            if (item.items.length === 0) return false;
          }
          return !this.routeDisabled(item.mobileSetting);
        });
      }
    },
    methods: {
      menuGenerate() {
        this.menus = this.$store.state.auth.user.isAdmin
          ? this.menuItems
          : this.menuItems.filter((item) => {
              if (Array.isArray(item.items)) {
                item.items = item.items.filter((subItem) =>
                  subItem.access_key ? this.$token_manager.get(subItem.access_key) : true
                );
              }
              return item.access_key ? this.$token_manager.get(item.access_key) : true;
            });
      }
    },
    created() {
      this.menuGenerate();
    }
  };
</script>

<style scoped></style>
